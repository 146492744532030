import React, { createContext, useEffect } from "react";
import useForm from "../hooks/useForm";
import { useTranslation } from "react-i18next";

export const DonationContext = createContext();

const DonationContextProvider = (props) => {
  const { t } = useTranslation();

  const {
    form,
    setForm,
    onInputChange,
    handleNumberChange,
    handleBooleanChange,
    handleMultiInputChange,
  } = useForm();

  const PAYMENT_TYPES = {
    card: "card",
    ideal: "ideal",
    sepa: "sepa_debit",
  };

  useEffect(() => {
    setForm((value) => ({
      ...value,
      quantity: 10,
      amount: 25,
      paymentType: PAYMENT_TYPES.sepa,
      hasSubscription: true,
    }));
  }, []);

  const setPaymentType = (type) => {
    if (form.paymentType === PAYMENT_TYPES.card) {
      return;
    }

    setForm((form) => ({
      ...form,
      paymentType: type,
    }));
  };

  return (
    <DonationContext.Provider
      value={{
        form,
        onInputChange,
        handleNumberChange,
        handleBooleanChange,
        handleMultiInputChange,
        PAYMENT_TYPES,
        setPaymentType,
      }}
    >
      {props.children}
    </DonationContext.Provider>
  );
};

export default DonationContextProvider;
