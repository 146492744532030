import React from "react";
import IbanInput from "./IbanInput";
import { useTranslation } from "react-i18next";

const NetlifySepaForm = ({ form }) => {
  const { t } = useTranslation();
  const { quantity } = form;

  return (
    <form name="sepa-form" method="post">
      <input type="hidden" name="form-name" value="sepa-form" />
      <input type="number" name="amount" value={quantity} hidden readOnly />
      <div className="mb-4">
        <label>
          <b className="block">{t("donations.payments.form.name")}</b>
          <input
            type="text"
            name="name"
            className="InputElement w-full"
            required
          />
        </label>
      </div>
      <div className="mb-4">
        <label>
          <b className="block">{t("donations.payments.form.email")}</b>
          <input
            type="email"
            name="email"
            className="InputElement w-full"
            required
          />
        </label>
      </div>
      <div className="mb-4">
        <label>
          <b className="block">{t("donations.payments.form.iban")}</b>
          <IbanInput />
        </label>
      </div>
      <button type="submit" className="mb-4">
        {t("btn.pay")}
      </button>
      <p className="c-p-sm text-gray-700">
        {t("donations.payments.form.iban_helper")}
      </p>
    </form>
  );
};

export default NetlifySepaForm;
