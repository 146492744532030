import React, { useContext, useRef } from "react";
import { DonationContext } from "../../contexts/DonationContext";

import DonationLabel from "./DonationLabel";
import { useTranslation } from "react-i18next";

const DonationAmount = () => {
  const { t } = useTranslation();
  const {
    form,
    handleNumberChange,
    handleBooleanChange,
    PAYMENT_TYPES,
    setPaymentType
  } = useContext(DonationContext);

  const setBillingTrueRef = useRef();
  const setBillingFalseRef = useRef();

  if (!form) {
    return <div>Loading</div>;
  }

  return (
    <div className="xl:grid">
      <div className="col-span-4 pr-4">
        <DonationLabel
          title={t("donations.amount.title")}
          text={t("donations.amount.subtitle")}
        />
      </div>
      <div className="col-span-8 align-middle">
        <label
          className={`block p-4 mb-4 rounded-lg bg-white shadow border-2 ${
            form.hasSubscription ? "border-jungle-300" : "border-white"
          }`}
        >
          <input
            ref={setBillingTrueRef}
            type="radio"
            name="hasSubscription"
            value={true}
            defaultChecked={true}
            onChange={event => {
              handleBooleanChange(event);
              setPaymentType(PAYMENT_TYPES.sepa);
            }}
            className="mr-4"
          />{" "}
          {t("donations.amount.labels.monthly")} €{" "}
          <input
            type="number"
            name="quantity"
            value={form.quantity}
            onChange={handleNumberChange}
            onFocus={() => setBillingTrueRef.current.click()}
            required
            className="InputElement"
          />{" "}
        </label>

        <label
          className={`block p-4 mb-4 rounded-lg bg-white shadow border-2 ${
            !form.hasSubscription ? "border-jungle-300" : "border-white"
          }`}
        >
          <input
            ref={setBillingFalseRef}
            type="radio"
            name="hasSubscription"
            value={false}
            onChange={event => {
              handleBooleanChange(event);
              setPaymentType(PAYMENT_TYPES.ideal);
            }}
            className="mr-4"
          />{" "}
          {t("donations.amount.labels.once")} €{" "}
          <input
            type="number"
            name="amount"
            value={form.amount}
            onChange={handleNumberChange}
            onFocus={() => setBillingFalseRef.current.click()}
            required
            className="InputElement"
          />{" "}
        </label>
      </div>
    </div>
  );
};

export default DonationAmount;
