import React from "react";
import IBAN from "./IBAN";

const IbanInput = props => (
  <IBAN
    {...props}
    render={({ onChange, value, iban, ...rest }) => (
      <>
        <input
          // {...rest}
          onChange={onChange}
          value={value}
          type="text"
          name="iban"
          className="InputElement w-full"
          required
        />
      </>
    )}
  />
);

export default IbanInput;
