import React from "react";
import Wrap from "../components/wrap";
import SEO from "../components/seo";

import { useTranslation } from "react-i18next";

import Donation from "../components/donation";
import DonationContextProvider from "../contexts/DonationContext";

// Demo
import Checkout from '../components/checkout'

function DonatePage() {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t("donations.title")}
        keywords={[`Doneer`, `Dolfijnen`, `Goed doel`, `Help dieren`]}
      />
      <div className="bg-jungle-300 text-white bg-leaf-2 bg-edge bg-edge--be-300">
        <Wrap>
          <h1 className="c-h2">{t("donations.title")}</h1>
          <p className="c-p-lg max-w-3xl">{t("donations.intro")}</p>
          <p className="c-p-lg max-w-3xl">{t("donations.anbi")}</p>
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=info%40wildlifewatchdogs.com&currency_code=EUR&source=url" target="_blank" className="c-button c-button--white">{t("donations.with_paypal")}</a>
        </Wrap>
        {/* <Wrap>
          <h1 className="c-h2">Test stripe checkout</h1>
          <Checkout />
        </Wrap> */}

      </div>
      <div className="bg-jungle-100">
        <Wrap>
          <h1 className="c-h2">{t("donations.title_directly")}</h1>
          <form
            name="sepa-form"
            netlify="true"
            data-netlify="true"
            netlify-honeypot="bot-field"
            hidden
          >
            <input type="text" name="name" />
            <input type="email" name="email" />
            <input type="text" name="iban" />
            <input type="number" name="amount" />
          </form>

          <DonationContextProvider>
            <Donation />
          </DonationContextProvider>
        </Wrap>
      </div>
    </>
  );
}

export default DonatePage;
