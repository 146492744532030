import React from "react";

const DonationLabel = ({ title, text }) => {
  return (
    <>
      <h2 className="c-h3 mb-2">{title}</h2>
      <p className="c-p">{text}</p>
    </>
  );
};

export default DonationLabel;
