import React, { useContext, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios'
import { DonationContext } from "../../contexts/DonationContext";

import DonationLabel from "./DonationLabel";
import NetlifySepaForm from "./NetlifySepaForm";

import { useTranslation } from "react-i18next";


let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
  }
  return stripePromise
}

const DonationPay = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { form, onInputChange, PAYMENT_TYPES } = useContext(DonationContext);

  if (!form) {
    return <div>Loading</div>;
  }

  const { quantity, amount, paymentType, hasSubscription } = form;

  const handleDonation = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(`${process.env.GATSBY_API_URL}/create-stripe-session`, {
        hasSubscription,
        paymentType,
        quantity: hasSubscription ? quantity : amount,
      });

      console.log('form', form)

      const stripe = await getStripe();

      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      })

      if (error) {
        console.warn("Error:", error);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <div className="xl:grid">
      <div className="col-span-4 pr-4">
        <DonationLabel
          title={t("donations.payments.title")}
          text={t("donations.payments.subtitle")}
        />
      </div>
      <div className="col-span-8 align-middle">
        {form.hasSubscription ? (
          <>
            <label
              className={`block p-4 mb-4 rounded-lg bg-white shadow border-2 ${
                form.paymentType === PAYMENT_TYPES.sepa
                  ? "border-jungle-300" : "border-white"
              }`}
            >
              <input
                type="radio"
                name="paymentType"
                onChange={onInputChange}
                value={PAYMENT_TYPES.sepa}
                checked={form.paymentType === PAYMENT_TYPES.sepa ? true : false}
                className="mr-4"
              />{" "}
              {t("donations.payments.labels.sepa")}
            </label>
          </>
        ) : (
          <>
            <label
              className={`block p-4 mb-4 rounded-lg bg-white shadow border-2   ${
                form.paymentType === PAYMENT_TYPES.ideal
                  ? "border-jungle-300" : "border-white"
              }`}
            >
              <input
                type="radio"
                name="paymentType"
                onChange={onInputChange}
                value={PAYMENT_TYPES.ideal}
                checked={
                  form.paymentType === PAYMENT_TYPES.ideal ? true : false
                }
                className="mr-4"
              />{" "}
              {t("donations.payments.labels.ideal")}
            </label>
          </>
        )}
        <label
          className={`block p-4 mb-4 rounded-lg bg-white shadow border-2   ${
            form.paymentType === PAYMENT_TYPES.card
              ? "border-jungle-300" : "border-white"
          }`}
        >
          <input
            type="radio"
            name="paymentType"
            onChange={onInputChange}
            value={PAYMENT_TYPES.card}
            checked={form.paymentType === PAYMENT_TYPES.card ? true : false}
            className="mr-4"
          />{" "}
          {t("donations.payments.labels.card")}
        </label>

        {form.paymentType === PAYMENT_TYPES.sepa && (
          <div className="block p-4 mb-4 rounded-lg bg-white shadow border-2 border-white">
            <NetlifySepaForm form={form} />
          </div>
        )}

        {form.paymentType === PAYMENT_TYPES.card && (
          <div className="block p-4 mb-4 rounded-lg bg-white shadow border-2 border-white">
            <button onClick={handleDonation} type="button" disabled={loading} className="mb-4">
              {t("btn.pay")}
            </button>
          </div>
        )}

        {form.paymentType === PAYMENT_TYPES.ideal && (
          <div className="block p-4 mb-4 rounded-lg bg-white shadow border-2 border-white">
            <button onClick={handleDonation} type="button" disabled={loading} className="mb-4">
              {t("btn.pay")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DonationPay;
