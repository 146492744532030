import { useState } from "react";

const useForm = callback => {
  const [form, setForm] = useState();

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const onInputChange = event => {
    event.persist();
    setForm(value => ({
      ...form,
      [event.target.name]: event.target.value
    }));
  };

  const handleNumberChange = event => {
    event.persist();
    setForm(value => ({
      ...form,
      [event.target.name]:
        event.target.value !== "" ? Number(event.target.value) : ""
    }));
  };

  const handleBooleanChange = event => {
    event.persist();
    setForm(value => ({
      ...form,
      [event.target.name]: event.target.value === "true" ? true : false
    }));
  };

  const handleMultiInputChange = event => {
    if (event.target.checked) {
      setForm({
        ...form,
        [event.target.name]: [...form[event.target.name], event.target.value]
      });
    } else {
      const filteredArray = form[event.target.name].filter(
        project => project !== event.target.value
      );
      setForm({
        ...form,
        [event.target.name]: filteredArray
      });
    }
  };

  return {
    form,
    setForm,
    handleSubmit,
    onInputChange,
    handleNumberChange,
    handleBooleanChange,
    handleMultiInputChange
  };
};

export default useForm;
