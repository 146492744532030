import React, { Fragment, useContext } from "react";
import { DonationContext } from "../contexts/DonationContext";

import Amount from "./donation/Amount";
import Payment from "./donation/Payment";

const Donation = () => {
  const { form } = useContext(DonationContext);

  if (!form) {
    return <div>Loading</div>;
  }

  return (
    <Fragment>
      <Amount />

      <hr className="my-12 border-jungle-200" />

      <Payment />
    </Fragment>
  );
};

export default Donation;
